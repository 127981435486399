import { Component, OnDestroy, OnInit } from "@angular/core"
import { AuthService } from "@app/auth"
import { EVENT_CATEGORY, REFERENTIAL, ReferentialService } from "@app/pages/referential/referential.service"
import { ActivatedRoute, Router } from "@angular/router"
import { DateUtils } from "@shared/util/date-utils"
import { DistributionService } from "@app/pages/program/distribution.service"
import {
  ExternNote,
  ExternViewInfo,
  PerformanceInfoExtern,
  PerformanceService,
} from "@app/pages/performance/peformance.service"
import { ProgramService } from "@app/pages/program/program.service"
import { CalendarService } from "@app/pages/planning/calendar.service"
import { NotifService } from "@shared/service/notif.service"
import { Location } from "@angular/common"

interface PerformanceView {
  date: Date
  dateStr: string
  skills: string
  codeName: string
  codeId: string
  perf: PerformanceInfoExtern
  perfId: string
  season: string
  program: string
  town: string
  paymentMin: string
  color: string
  personStatus: string
  personStatusColor: string
  statusPerf: string
  typePerf: string
  statusPerfColor: string
  showPlanning: boolean
  hasCache: boolean
  hasCalendarCache: boolean
  note_prio: string
  general_note: string
}

@Component({
  selector: "app-extern-performances",
  templateUrl: "./extern-performances.component.html",
  styleUrls: ["./extern-performances.component.scss"],
})
export class ExternPerformancesComponent implements OnInit, OnDestroy {
  now = new Date()
  timeDataLoaded = ""
  intervalTime: any = null
  loading = true
  loadingCache = true
  allPerformances: Array<PerformanceView> = []
  filteredPerfs: Array<PerformanceView> = []
  detailsDisplayed: Array<string> = []
  detailsNotes: Array<ExternNote> = []
  personFirstNameMap: { [personId: string]: string /*firstName*/ } = {}
  openPerfId = ""
  activeIndex: any = -1
  nextPerfId = ""
  filterValue = true
  filterOptions = [
    { label: "Futur", value: true },
    { label: "Passé", value: false },
  ]

  trackByPerfId(p: any): string {
    return p.perfId
  }

  constructor(
    private location: Location,
    private performanceService: PerformanceService,
    public authService: AuthService,
    private refService: ReferentialService,
    private router: Router,
    private distributionService: DistributionService,
    private programService: ProgramService,
    private calendarService: CalendarService,
    private notifService: NotifService,
    private route: ActivatedRoute
  ) {
    const uid = this.authService.currentUser?.id
    if (!uid) {
      console.error("uid is null!!")
      this.router.navigateByUrl("/")
      return
    }
    const qp = this.route.snapshot.queryParams
    if (qp.filter === "past") {
      this.filterValue = false
    }

    this.updateTimeUpdated(uid)
    this.intervalTime = setInterval(() => this.updateTimeUpdated(uid), 60 * 1000)

    Promise.all([
      this.refService.getAllFromCache(REFERENTIAL.person_status),
      this.refService.getAllFromCache(REFERENTIAL.skills),
      this.refService.getAllFromCache(REFERENTIAL.season),
      this.refService.getAllFromCache(REFERENTIAL.project_code),
      this.refService.getAllFromCache(REFERENTIAL.perf_status),
      this.refService.getAllFromCache(REFERENTIAL.perf_type),
      this.programService.getAllFromCache(),
    ]).then(([personStatusRef, skillsRef, seasonsRef, projectsCodesRef, perfStatusRef, perfTypeRef, programsRef]) => {
      const skillsKeys = skillsRef.reduce((a, v) => {
        a[v.id] = v.name
        return a
      }, {})

      const updateData = (perfsInfo: ExternViewInfo) => {
        let colorIndex = 0
        let currentSeason = ""
        this.personFirstNameMap = perfsInfo.persons
        this.allPerformances = perfsInfo.list.map((p) => {
          const skills =
            p.personsSkills &&
            p.personsSkills[uid] &&
            p.personsSkills[uid]
              .map((id) => {
                const s = skillsKeys[id]
                const a = s.split(" - ")
                return a[a.length > 1 ? 1 : 0]
              })
              .join(", ")

          const code = projectsCodesRef.find((r) => r.id === p.code?.id)?.name || ""
          const newSeason = seasonsRef.find((s) => s.id === p.season?.id)?.name || "..."
          if (currentSeason && newSeason != currentSeason) {
            colorIndex++
          }
          currentSeason = newSeason
          const personStatus = personStatusRef.find((x) => x.id === p.myStatus?.id)
          const perfStatus = perfStatusRef.find((x) => x.id === p.status_perf)
          const perfType = perfTypeRef.find((x) => x.id === p.type_perf)

          p.notes?.reverse()

          const results = {
            // name: code + " - " + p.dateStr || "",
            dateStr: new Date(p.date).toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            date: new Date(p.date),
            skills,
            codeName: code,
            codeId: p.code?.id,
            season: currentSeason,
            program: (p.program_title && programsRef.find((x) => x.id === p.program?.id)?.name) || "A définir",
            town: p.town,
            perf: p,
            perfId: p.id,
            paymentMin: p.paymentMin,
            paymentReal: p.paymentReal,
            color: `season${colorIndex % 4}`,
            personStatus: personStatus?.name || "",
            personStatusColor: personStatus?.color || "",
            note_prio: p.notes
              ?.filter((x: any) => x?.type === "prio")
              .map((x) => x?.text)
              .join("<br>"),
            statusPerf: perfStatus?.name || "",
            typePerf: perfType?.name || "",
            statusPerfColor: perfStatus?.color || "",
            showPlanning: p.showPlanning,
            general_note: p.general_note,
            hasCache: false,
            hasCalendarCache: false,
          }
          const userId = this.authService.currentUser?.id
          this.performanceService
            .existingOfflineData(`_extern_${userId}_${p.id}`)
            .then((value) => (results.hasCache = value))
          this.performanceService
            .existingOfflineData(`projects/${p.code?.id}/events`, false)
            .then((value) => (results.hasCalendarCache = value))
          return results
        })
        this.filterChange(true)
        this.nextPerfId =
          this.allPerformances.filter((p) => p.date > this.now).sort((a, b) => (a.date > b.date ? 1 : -1))[0]?.perfId ||
          ""
      }

      this.performanceService
        .getPerformancesInfoForExternId(uid, async (newData) => {
          if (newData) {
            localStorage["time-getPerformancesInfoForExternId-" + uid] = new Date()
            this.updateTimeUpdated(uid)
            //the cache has been loaded, fresh data are available here (newData)!
            await updateData(newData)
            if (
              window.location.href.includes("/extern-admin") ||
              window.location.href.includes("/extern/performances")
            ) {
              this.notifService.displaySuccess("La liste des répresentations (vue externe) est à jour!")
              if (this.activeIndex >= 0) {
                this.loadDetails({ index: this.activeIndex })
              }
            }
          }
          this.loadingCache = false
        })
        .then(async (info) => {
          await updateData(info)
          this.loading = false
        })
    })
  }

  updateTimeUpdated(uid: string): void {
    const date = new Date(localStorage["time-getPerformancesInfoForExternId-" + uid])
    if (isNaN(date.getTime())) {
      this.timeDataLoaded = "..."
    } else {
      this.timeDataLoaded = DateUtils.formatDD_MM_YYYY_HH_MM_SS(date)
      const duration = DateUtils.formatDuration((new Date().getTime() - date.getTime()) / 1000, "min.")
      this.timeDataLoaded += duration === "" ? " (maintenant)" : ` (il y a ${duration})`
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    clearInterval(this.intervalTime)
  }

  async filterChange(isHydrating?: boolean) {
    if (!isHydrating) this.activeIndex = -1
    this.location.go(`${window.location.pathname}?filter=${this.filterValue ? "future" : "past"}`)
    const now = new Date()
    const delta = 5 * 24 * 60 * 60 * 1000
    const deltaPast = new Date(Date.now() - delta)
    const deltaFuture = new Date(Date.now() + delta)
    this.filteredPerfs = []
    const futureFilter = this.filterValue
    const promises = this.allPerformances.map(async (perf) => {
      const isAlmostToday = deltaPast < perf.date && perf.date < deltaFuture
      let isFinished = true
      if (isAlmostToday) {
        isFinished = await this.isPerfFinished(perf)
      }
      if (futureFilter) {
        if (perf.date > now || (isAlmostToday && !isFinished)) this.filteredPerfs.push(perf)
      } else {
        if (perf.date <= now) this.filteredPerfs.push(perf)
      }
    })
    await Promise.all(promises)
    this.filteredPerfs.sort((a, b) => ((futureFilter ? a.date > b.date : a.date < b.date) ? 1 : -1))
  }

  gotoCalendar(performance: PerformanceView): void {
    this.router.navigateByUrl(
      `extern/planning/${performance.codeId}?date=${DateUtils.formatForApiCall(performance.date)}`
    )
  }

  async loadDetails(event: any): Promise<void> {
    const perfRoot = this.filteredPerfs[event.index]
    this.openPerfId = perfRoot?.perfId
    const perf = perfRoot.perf
    const userId = this.authService.currentUser?.id
    const myPersonId = this.authService.getMyPersonId()
    this.detailsDisplayed = ["..."]
    this.detailsNotes = []
    if (!perf || !userId) return

    const cache = await this.performanceService.getOfflineData(`_extern_${userId}_${this.openPerfId}`)
    if (cache && cache.length > 0) {
      this.detailsDisplayed = cache
      return
    }
    if (navigator.onLine === false) {
      this.detailsDisplayed.push(
        "<span class='text-red mt-3'>Vous êtes déconnecté, impossible de récuperer les informations (rien dans le cache).</span>"
      )
      return
    }

    const rolesDetails = await this.distributionService.getDistributionDetails(perf as any, this.personFirstNameMap)

    Promise.all([
      this.distributionService.getPersonDistribution(userId || "", perf as /*PerformanceDTO*/ any),
      perf.showTour
        ? this.calendarService.getEvents(perf.code?.id, true, [EVENT_CATEGORY.EXCLUDED_TOURNEE])
        : Promise.resolve([]),
    ]).then(([distrib, events]) => {
      this.detailsDisplayed = []

      // Tour
      events = events.filter((evt) => evt.resourceIds?.includes(userId))
      events.sort((a, b) => ((a.start || 0) > (b?.start || 0) ? 1 : -1))

      if (events[0]?.start) {
        const e = events[0]
        this.detailsDisplayed.push(
          `<b class="b">Début de tournée:</b><div>${DateUtils.formatdddd_DD_MM_YYYY_HH_MM(e.start || "")}<i><br>
[${events[0].title}]</i></div>`
        )
      }
      events.sort((a, b) => ((a.end || 0) < (b?.end || 0) ? 1 : -1))
      if (events[0]?.end) {
        const e = events[0]
        this.detailsDisplayed.push(`<br><b class="b">Fin de tournée:</b><div>${DateUtils.formatdddd_DD_MM_YYYY_HH_MM(
          e.end || ""
        )}
        <br><i>[${events[0].title}]</i></div>`)
      }
      if (this.detailsDisplayed.length > 0) {
        this.detailsDisplayed.unshift("<h3>Tournée " + perfRoot.codeName + "</h3>")
      }

      //Payments
      if (perf.paymentMin || perf.paymentReal) this.detailsDisplayed.push("<h3>Rémunération</h3>")
      if (perf.paymentMin) this.detailsDisplayed.push(`<b class="b">Rémunération totale prévue:</b>${perf.paymentMin}€`)
      if (perf.paymentMin && perf.paymentReal) this.detailsDisplayed.push("<br>")
      if (perf.paymentReal)
        this.detailsDisplayed.push(`<b class="b">Rémunération totale finalisée:</b>${perf.paymentReal}€`)

      //Notes
      this.detailsNotes = perf.notes?.filter((n) => n.type !== "prio") as any
      this.detailsNotes = [...this.detailsNotes, ...(perf.notes?.filter((n) => n.type === "prio") as any)]

      //Rôles
      if (perf.roles) {
        if (distrib.role.length > 0 || distrib.substitute.length > 0) this.detailsDisplayed.push("<h3>Rôles</h3>")
        if (distrib.role.length > 0) {
          const p = distrib.role.length > 1 ? "s" : ""
          this.detailsDisplayed.push(
            `<b class="b">Rôle${p}:</b>
              <div>${distrib.role
                .map((r) => `<b>${r.unit}</b> : <i class="darkblue">${r.role}</i>`)
                .join("<br>")}</div>`.trim()
          )
        }
        if (distrib.substitute.length > 0) {
          this.detailsDisplayed.push("<br><br>")
          const p = distrib.substitute.length > 1 ? "s" : ""
          this.detailsDisplayed.push(
            `<b class="b">Rôle${p} couvert${p}:</b><div>${distrib.substitute
              .map((r) => `<b>${r.unit}</b> : <i class="darkblue">${r.role}</i>`)
              .join("<br>")}</div>`.trim()
          )
        }
      }

      if (perf.programing) {
        const displayName = (e: any) => {
          return `<span class="${e.personId === myPersonId ? "text-orange-500" : ""}">${
            e.personName || '<span class="text-green-500">[à définir]</span>'
          }</span>`
        }
        const r = rolesDetails.map((r) => {
          const z = r.details.map(
            (value) =>
              `<span class="ml-4" ><span class="darkblue">${value.roleName}</span>
              :
              <span class="">${value.role.map((r) => displayName(r)).join(", ")}</span>
              <span class="opacity-50 ml-3">${
                value.substitutes.length > 0
                  ? "(c/ " + value.substitutes.map((r) => displayName(r)).join(", ") + ")"
                  : ""
              }</span>
              </span>`
          )
          return `<b>${r.unitName}</b><br>${z.join("<br>")}`
        })
        if (r.length > 0) {
          this.detailsDisplayed.push("<h3>Programmation</h3>")
          this.detailsDisplayed.push("<br>")
          this.detailsDisplayed.push(`<div>${r.join("<br>")}</div>`)
        }
      }
      this.performanceService.setOfflineData(`_extern_${userId}_${this.openPerfId}`, this.detailsDisplayed)
      const perf1 = this.allPerformances.find((pp) => pp.perf.id === perf.id)
      if (perf1) perf1.hasCache = true
    })
  }

  async isPerfFinished(perf: PerformanceView): Promise<boolean> {
    const userId = this.authService.currentUser?.id || ""
    let events = await this.calendarService.getEvents(perf.codeId, true, [EVENT_CATEGORY.EXCLUDED_TOURNEE])
    events = events.filter((evt) => evt.resourceIds?.includes(userId))
    events.sort((a, b) => ((a.end || 0) < (b?.end || 0) ? 1 : -1))
    const endDate = events[0]?.end || new Date()
    console.log("isPerfFinished", perf.codeName, endDate, endDate < new Date())
    return endDate < new Date()
  }
}
